<i18n lang="yaml">
pt:
  upgradeForStudioAccess: 'Faça upgrade para ter acesso ao estúdio e a customizações ilimitadas'
  weWillContactYouSoon: 'Entraremos em contato em breve!'
en:
  upgradeForStudioAccess: 'Upgrade your plan for studio access and unlimited customizations'
  weWillContactYouSoon: 'We will contact you soon!'
</i18n>

<template>
  <ThePaywallWall
    v-if="showWall"
    @cta-click="notifyContact"
  />
  <ThePaywallBar
    v-else-if="showBar"
    @cta-click="notifyContact"
  />
</template>

<script>
import { mapGetters, mapMutations } from '~/assets/javascript/modules/vuex';
import ThePaywallBar from './_bar';
import ThePaywallWall from './_wall';

export default {
  name: 'ThePaywall',
  components: {
    ThePaywallBar,
    ThePaywallWall,
  },
  setup() {
    return {
      t: useI18n().t,
      ...mapGetters('workspace', ['currentCategory', 'isChurned']),
      ...mapMutations('paywall', ['setBarNotApplicable']),
      ...mapGetters('view', ['isPostInstallationSetupView']),
    };
  },
  computed: {
    isZazosUser() {
      return Boolean(this.$auth.user?.username?.includes('@zazos.com'));
    },
    currentCategoryInTrial() {
      return this.currentCategory?.status === 'trial';
    },
    currentCategoryTrialEnded() {
      return this.currentCategory?.status === 'deactivated';
    },
    isAdmin() {
      return this.$auth.user?.role === 'admin';
    },
    showWall() {
      return (this.isChurned || this.currentCategoryTrialEnded) && !this.isZazosUser;
    },
    showBar() {
      return (this.isAdmin && this.currentCategoryInTrial && !this.isPostInstallationSetupView);
    },
  },
  watch: {
    currentCategoryInTrial: {
      immediate: true,
      handler(currentCategoryInTrial) {
        this.setBarNotApplicable(!currentCategoryInTrial);
      },
    },
  },
  methods: {
    notifyContact() {
      window.analytics.track('clickedPaywallContactUs');

      this.$notifier.showMessage({
        content: this.t('weWillContactYouSoon'),
        color: 'success',
      });
    },
  },
};
</script>
