<i18n lang="yaml">
pt:
  # Template interpolation plurals workaroud https://github.com/kazupon/vue-i18n/issues/471#issuecomment-878303520
  remainingTime: 'O {trialPeriod} deste app encerrará em {count}. Consulte nossos planos para manter seu acesso'
  days: 'hoje | 1 dia | {n} dias'
  trialPeriod: 'período de teste'
  hide: 'Esconder'
  plans: 'Planos'
en:
  remainingTime: 'The {trialPeriod} for this app will end in {count}. Upgrade your plan to retain access'
  days: 'today | 1 day | {n} days'
  trialPeriod: 'trial period'
  hide: 'Hide'
  plans: 'Plans'
</i18n>

<template>
  <div
    v-test-id="'the-paywall-bar'"
    class="the-paywall-bar elevation-1 py-2"
    :class="{
      'the-paywall-bar--hide': barHidden,
    }"
  >
    <div class="the-paywall-bar__main-content">
      <i18n-t
        tag="p"
        keypath="remainingTime"
        class="mb-0"
      >
        <template #trialPeriod>
          <strong>{{ t('trialPeriod') }}</strong>
        </template>
        <template #count>
          <strong>{{ t('days', currentCategory.trial_remaining_days, { n: currentCategory.trial_remaining_days }) }}</strong>
        </template>
      </i18n-t>

      <v-btn
        class="the-paywall-bar__cta"
        color="#FD8C41"
        :elevation="0"
        @click="openPlansPricingDialog"
      >
        {{ t('plans') }}
      </v-btn>
    </div>

    <v-icon
      class="the-paywall-bar__close-icon"
      :aria-label="t('hide')"
      @click="hideBar"
    >
      fa-xmark fa-regular
    </v-icon>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from '~/assets/javascript/modules/vuex';

export default {
  name: 'ThePaywallBar',
  emits: ['ctaClick'],
  setup() {
    return {
      t: useI18n().t,
      ...mapGetters('workspace', ['currentCategory']),
      ...mapGetters('paywall', ['barHidden']),
      ...mapActions('paywall', ['loadBarState', 'hideBar']),
      ...mapMutations('dialog', ['openDialog']),
    };
  },
  async beforeMount() {
    try {
      await this.loadBarState();
    } catch (error) {
      this.$errorRescue(this, error, 'loadPaywallBar');
    }
  },
  methods: {
    openPlansPricingDialog() {
      this.openDialog({
        component: 'DialogPlansPricing',
        maxWidth: '1000px',
        on: {
          onCtaClick: () => { this.$emit('ctaClick'); },
        },
      });
    },
  },
};
</script>

<style lang="scss">
.the-paywall-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  background-color: #003255;
  color: white;
  z-index: 201;
  transition: transform 0.3s ease-in-out;
}

.the-paywall-bar--hide {
  transform: translateY(-100%);
}

// this class is also used by intercom automation:
// https://app.intercom.com/a/apps/ci6rseap/operator/custom-bots/button-custom-bot/35203259
.the-paywall-bar__main-content {
  display: flex;
  align-items: center;
}

.the-paywall-bar__cta {
  margin-left: 16px;
  color: white !important;

  ::after {
    background-image: url("public/zazos-bolt.svg");
    background-size: contain;
    content: "";
    height: 40px;
    left: calc(100% + 32px);
    position: absolute;
    bottom: 8px;
    width: 96px;
    pointer-events: none;
  }
}

.the-paywall-bar__close-icon {
  position: absolute !important; // v-icon override
  right: 16px;
  color: white !important; // v-icon override
}
</style>
