<i18n lang="yaml">
pt:
  churned: 'O acesso a essa área de trabalho foi encerrado'
  timeIsOver: 'O período de teste do app de {app} encerrou!'
  contactUs: 'Falar conosco'
  headlineStart: 'Libere o'
  headlineEnd: 'do seu RH'
  centralizeTool: 'Centralize todo o seu RH em uma só ferramenta'
  getAPlan: 'Contrate um plano:'
  superPower: 'Super poder'
  illustration: 'Ilustração de uma mulher levantando pesos'
  checkPlans: 'Confira os planos disponíveis para restaurar seu acesso'
  plans: 'Planos'
  returnToHome: 'Voltar para página inicial'
en:
  churned: 'Access to this workspace has been terminated'
  timeIsOver: 'The trial period for the {app} app is over!'
  contactUs: 'Contact us'
  headlineStart: 'Unlock your'
  headlineEnd: 'HR potential'
  centralizeTool: 'Centralize all your HR in one tool'
  getAPlan: 'Get a plan:'
  superPower: 'Super power'
  illustration: 'Illustration of a woman lifting weights'
  checkPlans: 'Check out the available plans to restore your access'
  plans: 'Plans'
  returnToHome: 'Return to home'
</i18n>

<template>
  <div class="the-paywall-wall">
    <div class="the-paywall-wall__title">
      <div v-if="isChurned">
        {{ t('churned') }}
      </div>
      <i18n-t
        v-else
        tag="p"
        keypath="timeIsOver"
      >
        <template #app>
          <strong>{{ currentCategory.category_name }}</strong>
        </template>
      </i18n-t>
    </div>

    <div class="the-paywall-wall__subtitle">
      {{ t('checkPlans') }}
    </div>

    <v-card>
      <v-card-item>
        <v-card-title>
          {{ t('plans') }}
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <PricingTable
          @cta-click="$emit('ctaClick')"
        />
      </v-card-text>
    </v-card>

    <div
      v-if="showHomeLink"
      class="the-paywall-wall__redirect"
    >
      <a
        :href="homeUrl"
        class="the-paywall-wall__redirect-link"
      >
        {{ t('returnToHome') }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from '~/assets/javascript/modules/vuex';
import PricingTable from '~/components/pricing-table';

export default {
  name: 'ThePaywallWall',
  components: { PricingTable },
  props: {
    showHomeLink: { type: Boolean, default: false },
  },
  emits: ['ctaClick'],
  setup() {
    return {
      t: useI18n().t,
      ...mapGetters('workspace', ['currentCategory', 'tenantSlug', 'isChurned']),
    };
  },
  computed: {
    homeUrl() {
      return this.localePath({
        name: 't-tenantSlug-m',
        params: { tenantSlug: this.tenantSlug },
      });
    },
  },
};
</script>

<style lang="scss">
.the-paywall-wall {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding-block: 16px;
  background-color: rgb(2 46 85 / 60%);
  backdrop-filter: blur(3px);
  overflow: auto;
  z-index: 201;

  @supports (height: 100dvh) {
    width: 100dvw;
    height: 100dvh;
  }
}

.the-paywall-wall__title {
  color: white;
  font-size: 36px;
  font-weight: 800;
}

.the-paywall-wall__subtitle {
  color: white;
  margin-bottom: 16px;
}

.the-paywall-wall__redirect {
  bottom: 12px;
  right: 24px;
  position: fixed;
}

.the-paywall-wall__redirect-link {
  color: white;
}
</style>
